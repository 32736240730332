import React from 'react';
import { Link } from 'gatsby';

import Layout from "../components/Layout"
import Header from "../components/HomePage/Header"
import About from "../components/HomePage/About"
import AboutPodology from "../components/HomePage/AboutPodology"
import Pricing from "../components/HomePage/Pricing"
import Contact from "../components/HomePage/Contact"
import SEO from '../components/Seo';

const IndexPage = () => {
  return (
    <Layout >
      <SEO title="Strona główna" />
      <Header />
      <About />
      <AboutPodology />
      {/* miejsce na kalendarz */}
      <Contact />
    </Layout>
  );
}

export default IndexPage;
